import { ReactElement, useCallback } from 'react'
import { ChainId, Currency, Token } from '@pancakeswap/sdk'
import styled from 'styled-components'
import {
  Button,
  Text,
  ErrorIcon,
  ArrowUpIcon,
  Flex,
  Box,
  Link,
  Spinner,
  Modal,
  InjectedModalProps,
  ModalProps,
  NewError,
} from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { WrappedTokenInfo } from '@pancakeswap/token-lists'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { AutoColumn, ColumnCenter } from '../Layout/Column'
import { getBlockExploreLink, getBlockExploreName } from '../../utils'
import AddToWalletButton, { AddToWalletTextOptions } from '../AddToWallet/AddToWalletButton'
import { SuccessIcon } from '../../../public/icon'

const Wrapper = styled.div`
  minwidth: 375px;
`

const Section = styled(AutoColumn)`
  padding: 24px;
`

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 24px 0;
`

function ConfirmationPendingContent({ pendingText }: { pendingText: string }) {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <ConfirmedIcon>
        <Spinner />
      </ConfirmedIcon>
      <AutoColumn gap="12px" justify="center">
        <AutoColumn
          gap="8px"
          justify="center"
          style={{
            backgroundColor: '#1A1A1A',
            padding: '16px',
            marginTop: '8px',
            borderRadius: '24px',
            width: '100%',
            height: '100%',
            minHeight: '100px',
            alignItems: 'center',
          }}
        >
          <Text fontSize="14px" fontWeight="600" color="#fff">
            {t('Waiting For Confirmation')}
          </Text>
          <Text bold small textAlign="center">
            {pendingText}
          </Text>
        </AutoColumn>
        <Text small color="#b4b4b4" fontSize="12px" fontWeight={400} textAlign="center">
          {t('Confirm this transaction in your wallet')}
        </Text>
      </AutoColumn>
    </Wrapper>
  )
}

export function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  currencyToAdd,
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  currencyToAdd?: Currency | undefined
}) {
  const { t } = useTranslation()

  const token: Token | undefined = wrappedCurrency(currencyToAdd, chainId)

  return (
    <Wrapper>
      <Section
        style={{
          padding: '0px',
          position: 'relative',
        }}
      >
        <ConfirmedIcon>
          <SuccessIcon />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify="center">
          {/* <Text fontSize="20px" fontWeight={600} color={'#fff'}>
            {t('Transaction Submitted')}
          </Text>

          {currencyToAdd && (
            <AddToWalletButton
              variant="tertiary"
              style={{ background: 'none', fontSize: '14px', fontWeight: '400' }}
              width="fit-content"
              marginTextBetweenLogo="6px"
              textOptions={AddToWalletTextOptions.TEXT_WITH_ASSET}
              tokenAddress={token.address}
              tokenSymbol={currencyToAdd.symbol}
              tokenDecimals={token.decimals}
              tokenLogo={token instanceof WrappedTokenInfo ? token.logoURI : undefined}
            />
          )} */}
          <Button isBlock width="100%" height="52px" style={{ borderRadius: '26px' }} onClick={onDismiss} mt="20px">
            {t('Close')}
          </Button>
          {chainId && hash && (
            <Link
              external
              target="_blank"
              color="primary"
              fontWeight="500"
              fontSize="12px"
              small
              href={getBlockExploreLink(hash, 'transaction', chainId)}
            >
              {t('View on Explorer')}
              {/* {t('View on %site%', {
                site: getBlockExploreName(chainId),
              })} */}
            </Link>
          )}
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export function ConfirmationModalContent({
  bottomContent,
  topContent,
}: {
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}) {
  return (
    <Wrapper>
      <Box>{topContent()}</Box>
      <Box>{bottomContent()}</Box>
    </Wrapper>
  )
}

export function TransactionErrorContent({
  message,
  onDismiss,
}: {
  message: ReactElement | string
  onDismiss?: () => void
}) {
  const { t } = useTranslation()
  return (
    <Wrapper
      style={{
        position: 'relative',
      }}
    >
      <AutoColumn justify="center">
        <NewError width="100px" />
        <AutoColumn
          gap="8px"
          justify="center"
          style={{
            backgroundColor: '#1A1A1A',
            padding: '16px',
            marginTop: '8px',
            borderRadius: '24px',
            width: '100%',
            height: '100%',
            minHeight: '100px',
            alignItems: 'center',
          }}
        >
          <Text color="white" fontSize="14px" fontWeight="600" style={{ textAlign: 'center', wordBreak: 'break-word' }}>
            {message}
          </Text>
        </AutoColumn>
      </AutoColumn>

      {onDismiss ? (
        <Flex justifyContent="center" pt="24px">
          <Button width="100%" style={{ borderRadius: '24px' }} onClick={onDismiss}>
            {t('Close')}
          </Button>
        </Flex>
      ) : null}
    </Wrapper>
  )
}

interface ConfirmationModalProps {
  title: string
  customOnDismiss?: () => void
  hash: string | undefined
  content: () => React.ReactNode
  attemptingTxn: boolean
  pendingText: string
  currencyToAdd?: Currency | undefined
}

const TransactionConfirmationModal: React.FC<
  React.PropsWithChildren<InjectedModalProps & ConfirmationModalProps & ModalProps>
> = ({ title, onDismiss, customOnDismiss, attemptingTxn, hash, pendingText, content, currencyToAdd, ...props }) => {
  const { chainId } = useActiveChainId()

  const handleDismiss = useCallback(() => {
    if (customOnDismiss) {
      customOnDismiss()
    }
    onDismiss?.()
  }, [customOnDismiss, onDismiss])

  if (!chainId) return null

  return (
    <Modal
      title={hash ? 'Swap Success' : title}
      headerBackground="gradientCardHeader"
      {...props}
      onDismiss={handleDismiss}
    >
      {attemptingTxn ? (
        <ConfirmationPendingContent pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={handleDismiss}
          currencyToAdd={currencyToAdd}
        />
      ) : (
        content()
      )}
    </Modal>
  )
}

export default TransactionConfirmationModal
