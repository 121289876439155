import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        opacity="0.3"
        d="M50.0003 16.6667C31.5837 16.6667 16.667 31.5833 16.667 50C16.667 68.4167 31.5837 83.3333 50.0003 83.3333C68.417 83.3333 83.3337 68.4167 83.3337 50C83.3337 31.5833 68.417 16.6667 50.0003 16.6667ZM54.167 70.8333H45.8337V62.5H54.167V70.8333ZM54.167 54.1667H45.8337V29.1667H54.167V54.1667Z"
        fill="#FDE53A"
      />
      <path
        d="M49.958 8.33334C26.958 8.33334 8.33301 27 8.33301 50C8.33301 73 26.958 91.6667 49.958 91.6667C72.9997 91.6667 91.6664 73 91.6664 50C91.6664 27 72.9997 8.33334 49.958 8.33334ZM49.9997 83.3334C31.583 83.3334 16.6663 68.4167 16.6663 50C16.6663 31.5833 31.583 16.6667 49.9997 16.6667C68.4163 16.6667 83.333 31.5833 83.333 50C83.333 68.4167 68.4163 83.3334 49.9997 83.3334ZM45.833 62.5H54.1663V70.8333H45.833V62.5ZM45.833 29.1667H54.1663V54.1667H45.833V29.1667Z"
        fill="#FDE53A"
      />
    </Svg>
  );
};

export default Icon;
